import * as autocompleteMenuHandler from './autocompleteMenuHandler';
import Menu from './Menu';
import { SearchResults, Suggestion } from '../../types/search';

export interface IAutocompleteListMenu {
    inputEl: HTMLInputElement;
    menuEl: HTMLElement;
    menuListEl?: HTMLElement;
    handleClickOutside(): void;
    clear(): void;
}

export default abstract class AutocompleteListMenu extends Menu implements IAutocompleteListMenu {
    private _inputEl: HTMLInputElement;
    private _menuEl: HTMLElement;
    private _menuListEl: HTMLElement;
    private _keyboardHandler: any;

    constructor(
        inputEl: HTMLInputElement,
        menuEl: HTMLElement,
        menuListEl: HTMLElement,
        onMenuOpen?: () => any,
        onMenuClose?: () => any
    ) {
        super(onMenuOpen, onMenuClose);
        this._inputEl = inputEl;
        this._menuEl = menuEl;
        this._menuListEl = menuListEl;
        this._keyboardHandler = autocompleteMenuHandler;
    }

    get inputEl(): HTMLInputElement {
        return this._inputEl;
    }

    get menuEl(): HTMLElement {
        return this._menuEl;
    }

    get menuListEl(): HTMLElement | undefined {
        return this._menuListEl;
    }

    get keyboardHandler(): any {
        return this._keyboardHandler;
    }

    public abstract buildSuggestions(listItems: SearchResults): HTMLElement;
    public abstract buildIneligibleTermsAlternatesSuggestions(suggestions: Suggestion[]): HTMLElement;

    public handleClickOutside(): void {
        super.handleClickOutside();
        this.clear();
    }

    public render(listItems: SearchResults) {
        if (listItems) {
            if (this.menuListEl) {
                if (this.menuListEl.children.length > 0) {
                    this.updateListMenu(listItems);
                } else {
                    this.clear();
                    this.createListMenu(listItems);
                }

                if (!this.open) {
                    this.openMenu();
                }
            }
        } else {
            this.closeMenu();
            this.clear();
        }
    }

    public clear(): void {
        if (this.menuListEl) {
            this.menuEl.innerHTML = '';
            this.menuListEl.innerHTML = '';
        }
    }

    private createListMenu(listItems: SearchResults) {
        if (listItems) {
            if (listItems.ineligibleTermsAlternates.length > 0) {
                this.buildIneligibleTermsAlternatesSuggestions(listItems.ineligibleTermsAlternates);
            }
            else {
                this.buildSuggestions(listItems);
            }
        }
    }

    private updateListMenu(listItems: SearchResults) {
        const suggestionsSearchListHtml = document.querySelector('suggestions');
        if (suggestionsSearchListHtml) {
            suggestionsSearchListHtml.innerHTML = '';
            this.createListMenu(listItems);
        }
    }
}